import { LoginOutlined } from '@mui/icons-material'
import { Alert, Box, Button, TextField, Typography } from '@mui/material'
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import authService from '../../services/authService'
import { useDispatch, useSelector } from 'react-redux'
import { signIn } from '../../features/auth/authSlice'

export const Login = () => {

    const [credentials, setCredentials] = useState(null)
    const [errorMessage, setErrorMessage] = useState("")
    const { isAuthorized, message, isError } = useSelector(state => state.auth)

    const dispatch = useDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        if(isAuthorized === true){
            navigate('/')
        }
    }, [isAuthorized])
    
    const handleSubmit = (event) => {
        event.preventDefault()
        onLogin()
    }

    const handleInputs = (event) => {
        const name = event.target.name
        const value = event.target.value

        setCredentials((prev) => ({...prev, [name] : value}))
    }

    const onLogin = async () => {
        // const res = await authService.login({ userCredentials : credentials })
        // if (res.response?.status == 401){
        //     setIsError(true)
        // }else{
        //     // dispatch()
        //     localStorage.setItem('auth', JSON.stringify(res.data))
        //     navigate('/')
        // }
        // // console.log(res)
        // setErrorMessage(res.response.data.message)

        dispatch(signIn({ credentials }))
    }
  return <>
    <Box sx={{
        display : 'flex',
        justifyContent : 'center',
        alignItems : 'center',
        width : '100%'
    }}>
        <Box sx={{
            display : 'flex',
            flexDirection : 'column',
            alignItems : 'center',
            justifyContent : 'center',
            width : '26%',
            marginTop : '20rem'
        }}>
            <Typography variant='h3' component='p' color='secondary'>COCKPIT</Typography>
            
            <Box sx={{
                width : '100%',
                display : 'flex',
                flexDirection : 'column',
                justifyContent : 'center',
                alignItems : 'center',
                marginTop : '3rem'
            }}>
                {isError && <Alert severity="error" sx={{ marginBottom : '1rem', width : '100%' }}>{message}</Alert>}
                <form onSubmit={handleSubmit} style={{ width : '100%' }}>
                    <Box width='100%'>
                        <TextField size='small' color='secondary' label='Username' required fullWidth name='email' onChange={handleInputs} type='text'/>
                    </Box>
                    <Box width={'100%'} marginY={'0.8rem'}>
                        <TextField size='small' color='secondary' label='Password' required type='password' fullWidth name='password' onChange={handleInputs}/>
                    </Box>
                    <Box width={'100%'}>
                        <Button type='submit' startIcon={<LoginOutlined/>} fullWidth size='small' variant='contained' color='info'>LOGIN</Button>
                    </Box>
                </form>
            </Box>
        </Box>
    </Box>
  </>
}
