import React, { useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, List, ListItem, ListItemText, IconButton, Typography, Box } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useDispatch } from 'react-redux'
import clientInformationService from '../../services/clientInformationService';

const ClientListModal = ({ open, setOpen, clients, setClients }) => {
    const dispatch = useDispatch();

    // State to manage modal open/close
    const [confirmationOpen, setConfirmationOpen] = useState(false);
    const [clientToDelete, setClientToDelete] = useState(null);

    // Function to handle closing the modal
    const handleClose = () => {
        setOpen(false);
        setConfirmationOpen(false); // Close confirmation dialog as well
    };

    // Function to show confirmation dialog
    const showConfirmationDialog = (id) => {
        setClientToDelete(id);
        setConfirmationOpen(true);
    };

    // Function to delete a client with confirmation
    const handledeleteClient = async () => {
        try {
            const res = await clientInformationService.DeleteClient({ id: clientToDelete, dispatch });
            // Remove the client from the list in state
            setClients((prevClients) => prevClients.filter((client) => client.id !== clientToDelete));

            // Close confirmation dialog
            setConfirmationOpen(false);
            setOpen(false);
            // Optionally, show a success message
        } catch (error) {
            console.error("Error deleting client:", error);
            // Optionally, show an error message
        }
    };

    return (
        <div>
            {/* Main Modal */}
            <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
                <DialogTitle sx={{
                    textAlign: 'center',
                    backgroundColor: '#333',
                    fontWeight: 'bold',
                    fontSize: '18px',
                    color: 'white'
                }}>
                    Client List
                </DialogTitle>
                <DialogContent sx={{
                    color: 'white'
                }}>
                    {/* List of clients */}
                    <List sx={{ padding: 1 }}>
                        {clients.map((client) => (
                            <ListItem key={client.id} sx={{
                                backgroundColor: '#424242',
                                marginBottom: '1rem',
                                borderRadius: '8px',
                                boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                            }}>
                                <ListItemText
                                    primary={<Typography sx={{ fontWeight: 'bold', color: '#fff' }}>{client.name}</Typography>}
                                    secondary={<Typography sx={{ color: '#bbb', fontSize: '12px' }}>{`ID: ${client.id}`}</Typography>}
                                />
                                {/* Delete button */}
                                <IconButton onClick={() => showConfirmationDialog(client.id)} edge="end" aria-label="delete">
                                    <DeleteIcon sx={{ color: '#f44336' }} />
                                </IconButton>
                            </ListItem>
                        ))}
                    </List>
                </DialogContent>
                <DialogActions sx={{ padding: '1rem', backgroundColor: '#333' }}>
                    <Button onClick={handleClose} variant="contained" color="secondary" sx={{ borderRadius: '20px', padding: '0.5rem 2rem' }}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Confirmation Dialog */}
            <Dialog open={confirmationOpen} onClose={() => setConfirmationOpen(false)} fullWidth maxWidth="sm">
                <DialogTitle sx={{ textAlign: 'center', color: 'white', backgroundColor: '#333' }}>
                    Confirm Deletion
                </DialogTitle>
                <DialogContent sx={{ color: 'white' }}>
                    <Typography variant="h6" sx={{ color: 'white', textAlign: 'center', mt: 4 }}>
                        Are you sure you want to delete this client?
                    </Typography>

                    <Box sx={{ gap: 2, display: 'flex', alignItems: 'center', justifyContent: 'center', mt: 4 }}>
                        <Button onClick={() => setConfirmationOpen(false)} variant="outlined" color="inherit" sx={{ borderRadius: '20px' }}>
                            Cancel
                        </Button>
                        <Button onClick={handledeleteClient} variant="contained" color="error" sx={{ borderRadius: '20px' }}>
                            Confirm Delete
                        </Button>
                    </Box>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default ClientListModal;
