import { Box, Button, Checkbox, FormControl, FormControlLabel, InputLabel, MenuItem, Select, Typography } from '@mui/material'
import { Form, Formik } from 'formik'
import React from 'react'
import initialValues from '../../../../validators/clientinformation/initialValues'
import { InputField } from '../../../common/InputField'
import saveClientInformation from '../../../../validators/clientinformation/saveClientInformation'
import { DatePickerField } from '../../../common/DatePickerField'
import saveClientInformationValidator from '../../../../validators/clientinformation/saveClientInformationValidator'
import { SelectInput } from '../../../common/SelectInput'
import { CheckBox } from '@mui/icons-material'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { userRole } from '../../../../constants/userRole'

const reportingPeriods = [{ keyVal : '', value : 'Please Choose' }, { keyVal : 'DY', value : 'Daily' }, { keyVal : 'WY', value : 'Weekly' }, { keyVal : '2M', value : '2x Monthly' }, { keyVal : 'MY', value : 'Monthly' }, { keyVal : 'QY', value : 'Quarterly' }, { keyVal : 'SA', value : 'Semi-Annual' }, { keyVal : 'YY', value : 'Yearly' }]
export const SaveClientInfoForm = ({ toEdit = false, clientInfoData, onClose, onSubmit, addClient }) => {

  const { formId, formField : { accountManager, missionStatement, name, premium, reportingPeriod, securityChampion, startDate, secopsSME, customerSuccessRep } } = saveClientInformation
  const { isLoading } = useSelector(state => state.clientInfo)
  const { stakeholders } = useSelector(state => state.stakeholder)
  console.log('stakeholders ', stakeholders)
  return (
    <Box>
        <Formik   
          initialValues={toEdit ? {
            [name.name] : clientInfoData.name,
            [startDate.name] : clientInfoData.start_date,
            [missionStatement.name] : clientInfoData.mission_statement,
            [accountManager.name] : clientInfoData.account_manager,
            [securityChampion.name] : clientInfoData.security_champion,
            [premium.name] : Boolean(clientInfoData.premium),
            [reportingPeriod.name] : clientInfoData.reporting_period,
            [secopsSME.name] : clientInfoData.sec_ops_sme,
            [customerSuccessRep.name] : clientInfoData.customer_success_rep
          } : initialValues}
          validationSchema={saveClientInformationValidator}
          onSubmit={(event) => {
            
            const { id } = clientInfoData ? clientInfoData : { id : 0 }

            const data = {
              id,
              name : event[name.name],
              start_date : toEdit ? moment((event[startDate.name]['$d'] === undefined) ? event[startDate.name] : event[startDate.name]['$d']).format('YYYY-MM-DD') : moment((event[startDate.name] === '') ? new Date().toISOString() : event[startDate.name]['$d']).format('YYYY-MM-DD'),
              mission_statement : event[missionStatement.name],
              account_manager : event[accountManager.name],
              security_champion : event[securityChampion.name],
              premium : event[premium.name],
              reporting_period : event[reportingPeriod.name],
              sec_ops_sme : event[secopsSME.name],
              customer_success_rep : event[customerSuccessRep.name]
            }
            onSubmit(data)
          }}>
            {({ errors, setFieldValue, values, isSubmitting }) => (
              <Form id={formId}>
                <Box sx={{
                  display : 'flex',
                  flexDirection : 'column',
                  justifyContent : 'center',
                  alignItems : 'center',
                  width : '100%'
                }}>
                  <Box width={'100%'} marginBottom={'1rem'}>
                    <InputField name={name.name} label='Name' color='primary' fullWidth/>
                  </Box>
                  <Box width={'100%'} marginBottom={'1rem'}>
                    <DatePickerField name={startDate.name} label='Start Date'/>
                  </Box>
                  <Box width={'100%'} marginBottom={'1rem'}>
                    <InputField name={accountManager.name} label='Account Manager' color='primary' fullWidth/>
                  </Box>
                  <Box width={'100%'} marginBottom={'1rem'}>
                    <InputField name={missionStatement.name} label='Mission Statement' color='primary' fullWidth/>
                  </Box>
                  <Box width={'100%'} marginBottom={'1rem'}>
                    <InputField name={securityChampion.name} label='Security Champion' color='primary' fullWidth/>
                  </Box>
                  <Box width={'100%'} marginBottom={'1rem'} sx={{
                    display : 'flex',
                    justifyContent : 'center',
                    alignItems : 'center'
                  }}>
                    <Box width={'100%'}>
                      <FormControl fullWidth color='info'>
                          <InputLabel id="demo-simple-select-label">Reporting Period</InputLabel>
                          <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              label="Age"
                              value={values[reportingPeriod.name]}
                              onChange={(event) => {
                                setFieldValue(reportingPeriod.name, event.target.value)
                              }}
                          >
                              {reportingPeriods.map((reportingPeriod, i) => (
                                  <MenuItem key={i} value={reportingPeriod.keyVal} >{reportingPeriod.value}</MenuItem>
                              ))}
                          </Select>
                      </FormControl>
                      <Typography sx={{ marginLeft : '1rem' }} variant='subtitle2' component={'p'} fontSize={'12px'} color={'error'}>{errors[reportingPeriod.name]}</Typography>
                    </Box>
                    <Box width={'100%'} marginLeft={'1rem'}>
                      <FormControlLabel checked={values[premium.name]} control={<Checkbox onChange={(event) => {
                        setFieldValue(premium.name, event.target.checked)
                      }}/>} label='Premium'/>
                    </Box>
                  </Box>
                  {
                    addClient ? 
                      <>
                        <Box width={'100%'} marginBottom={'1rem'}>
                          <InputField name={secopsSME.name} label='SecOps SME' color='primary' fullWidth/>
                        </Box>
                        <Box width={'100%'} marginBottom={'1rem'}>
                          <InputField name={customerSuccessRep.name} label='Customer Success Rep' color='primary' fullWidth/>
                        </Box>
                      </> : 
                      <Box width={'100%'} marginBottom={'1rem'} sx={{
                        display : 'flex',
                        justifyContent : 'center',
                        alignItems : 'center'
                      }}>
                        <Box width={'100%'} marginRight={'5px'}>
                          <FormControl fullWidth color='info' disabled={JSON.parse(localStorage.getItem('auth')).role_name !== userRole.CYTECH_ADMIN}>
                              <InputLabel id="demo-simple-select-label">SecOps SME</InputLabel>
                              <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  label="Age"
                                  value={values[secopsSME.name]}
                                  onChange={(event) => {
                                    setFieldValue(secopsSME.name, event.target.value)
                                  }}
                              >
                                  {stakeholders.map((s, i) => (
                                      <MenuItem key={i} value={s.name} >{s.name}</MenuItem>
                                  ))}
                              </Select>
                          </FormControl>
                          <Typography sx={{ marginLeft : '1rem' }} variant='subtitle2' component={'p'} fontSize={'12px'} color={'error'}>{errors[reportingPeriod.name]}</Typography>
                        </Box>
                        <Box width={'100%'}>
                          <FormControl fullWidth color='info' disabled={JSON.parse(localStorage.getItem('auth')).role_name !== userRole.CYTECH_ADMIN}>
                              <InputLabel id="demo-simple-select-label">Customer Success Rep</InputLabel>
                              <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  label="Age"
                                  value={values[customerSuccessRep.name]}
                                  onChange={(event) => {
                                    setFieldValue(customerSuccessRep.name, event.target.value)
                                  }}
                              >
                                  {stakeholders.map((s, i) => (
                                      <MenuItem key={i} value={s.name} >{s.name}</MenuItem>
                                  ))}
                              </Select>
                          </FormControl>
                          <Typography sx={{ marginLeft : '1rem' }} variant='subtitle2' component={'p'} fontSize={'12px'} color={'error'}>{errors[reportingPeriod.name]}</Typography>
                        </Box>
                      </Box>
                  }
                  <Button disabled={isLoading} fullWidth size='large' color='info' variant='contained' type='submit'>SAVE</Button>
                </Box>
              </Form>
            )}
        </Formik>
    </Box>
  )
}
