import axios from "axios";
import { apiUrl } from "../constants/urls";
import { handleUnauthorized } from "../features/auth/authSlice";
import httpClient from "../config/axiosConfig";

const getAll = async (dispatch) => {
    try {
        const token = JSON.parse(localStorage.getItem('auth'))?.access_token
        const res = await axios.get(`${apiUrl}clientinfo`, {
            headers : { 
                Authorization : `Bearer ${token}`
            }
        })
        return res
    } catch (error) {
        if (Number(error.response.status) === 401) {
            dispatch(handleUnauthorized())
        }
        return error
    }
}

const getOne = async ({ id, dispatch }) => {
    try {
        const token = JSON.parse(localStorage.getItem('auth'))?.access_token
        const res = await axios.get(`${apiUrl}clientinfo/${id}`, {
            headers : { 
                Authorization : `Bearer ${token}`
            }
        })
        return res
    } catch (error) {

        if (Number(error.response.status) === 401) {
            dispatch(handleUnauthorized())
        }
        return error
    }
}

const update = async ({ updatedData, clientId, dispatch }) => {
    try {
        const token = JSON.parse(localStorage.getItem('auth'))?.access_token
        // const res = await axios.put(`${apiUrl}clientinfo/${clientId}/`, updatedData, {
        //     headers : { 
        //         Authorization : `Bearer ${token}`
        //     }
        // })
        const res = await httpClient.put(`clientinfo/${clientId}/`, updatedData, {
            headers : { 
                Authorization : `Bearer ${token}`
            }
        })
        return res
    } catch (error) {        
        return error
    }
}

const post = async ({ clientData, dispatch }) => {
    try {
        const token = JSON.parse(localStorage.getItem('auth'))?.access_token;
        const res = await axios.post(`${apiUrl}clientinfo/`, clientData, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return res;
    } catch (error) {
        if (Number(error.response.status) === 401) {
            dispatch(handleUnauthorized());
        }
        throw error;
    }
};

const DeleteClient = async ({ id, dispatch }) => {
    try {
        const token = JSON.parse(localStorage.getItem('auth'))?.access_token;

        const res = await axios.patch(`${apiUrl}clientinfo/${id}/update_premium_status/`, {}, {
            headers: { 
                Authorization: `Bearer ${token}` 
            }
        });

        return res;
    } catch (error) {
        if (error.response && Number(error.response.status) === 401) {
            dispatch(handleUnauthorized());
        }
        return error;
    }
};

const clientInformationService = {
    getAll,
    getOne,
    update,
    post,
    DeleteClient,

}

export default clientInformationService;