import { jwtDecode } from "jwt-decode";

export const validateToken = (token) => {
    try {
        const decoded = jwtDecode(token);
        const currentTime = Date.now() / 1000; // Current time in seconds
        if (decoded.exp && decoded.exp < currentTime) {
            console.error("Token has expired.");
            return false;
        }
        return true;
    } catch (error) {
        console.error("Invalid token:", error);
        return false;
    }
};

// Check if auth exists and validate token
export const checkAndValidateAuth = () => {
    const auth = localStorage.getItem("auth");

    // Check if "auth" exists
    if (!auth) {
        console.warn("Auth is undefined. Redirecting to login...");
        // window.location.href = "/login"; // Redirect to login page
        return null;
    }

    try {
        const parsedAuth = JSON.parse(auth);
        const token = parsedAuth?.access_token;

        // Validate the token
        if (!validateToken(token)) {
            console.warn("Token is invalid or expired. Redirecting to login...");
            localStorage.removeItem("auth");
            window.location.href = "/login"; // Redirect to login page
            return null;
        }

        return parsedAuth; // Return the auth object if the token is valid
    } catch (error) {
        console.error("Error parsing auth:", error);
        localStorage.removeItem("auth");
        window.location.href = "/login"; // Redirect to login page
        return null;
    }
};
