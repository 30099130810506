import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import authService from "../../services/authService";
import { checkAndValidateAuth } from '../../helpers/token';


// Example usage in the initial state
const initialState = {
    payload: checkAndValidateAuth(), // Valid auth object or null
    isLoading: false,
    token: checkAndValidateAuth()?.access_token || null,
    isAuthorized: checkAndValidateAuth() !== null,
    message: "",
    isError: false,
};

// const initialState = {
//     payload : localStorage.getItem('auth'),
//     isLoading : false,
//     token : JSON.parse(localStorage.getItem('auth'))?.access_token,
//     isAuthorized : localStorage.getItem('auth') !== null,
//     message : '',
//     isError : false
// }



export const signIn = createAsyncThunk(
    'auth/signin',
    async (params, thunk) => {
        try {
            const res = await authService.login({userCredentials : params.credentials})

            if(res?.code === 'ERR_NETWORK'){
                throw new Error('The service is not available at this time. Please try again later.')
            }

            if(res.response?.status === 400) {
                throw new Error(String(res.response.data.message))
            } 
            if(res.response?.status === 401) {
                localStorage.removeItem("auth");
                window.location.href = "/login";
            } 
            else{
                return res.data
            }
        } catch (error) {
            return thunk.rejectWithValue(error)
        }
    }
)


const authSlice = createSlice({
    name : 'auth',
    initialState,
    reducers : {
        clear : (state) => {
            state.payload = null
            state.token = ''
            state.isAuthorized = false
            state.message = ''
            state.isError = false
            localStorage.clear()
        },
        handleUnauthorized : (state) => {
            state.payload = null
            state.token = ''
            state.isAuthorized = false
            state.message = 'You were signed out of your account. Please sign in again.'
            state.isError = true
            localStorage.clear()
            // window.location.reload()
        }
    },
    extraReducers : (builder) => {
        builder.addCase(signIn.pending, (state) => {
            state.isLoading = true
            state.payload = null
            state.token = ''
            state.isError = false
            state.isAuthorized = false
        }).addCase(signIn.fulfilled, (state, action) => {
            state.isLoading = false
            state.payload = action.payload
            state.token = action.payload?.access_token
            state.message = ''
            state.isError = false
            state.isAuthorized = true
            localStorage.setItem('auth', JSON.stringify(action.payload))
        }).addCase(signIn.rejected, (state, action) => {
            state.isLoading = false
            state.payload = null
            state.token = null
            state.message = action.payload?.message
            state.isError = true
            state.isAuthorized = false
        })
    }
})

export const { clear,handleUnauthorized } = authSlice.actions
export default authSlice.reducer